import "../App.css";
import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import Progress from "../components/common/Progress/Progress";
import SignUp from "../components/common/SignUp/SignUp";

const SignUpPage = () => {



  const title = "ImageMall | SignUp";

  // use effect
  useEffect(() => {
    // hide top loader after component load

  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SignUp main={true} />
    </>
  );
}



export default SignUpPage;