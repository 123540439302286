import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Backdrop } from '@mui/material';


const Progress = (props) => {

    const loading = props.loading

    if (loading) {
        return (
            <>
                <Box sx={{ width: '100%' }} className="top-progress">
                    <LinearProgress variant={props.variant} />
                </Box>
                {/* <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                </Backdrop> */}
            </>
        );
    }
}
export default Progress