import "../App.css";
import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import Progress from "../components/common/Progress/Progress";
import Login from '../components/common/Login/Login'

const LoginPage = () => {


  const title = "ImageMall | Login";

  // use effect
  useEffect(() => {
    // hide top loader after component load
  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      
      <Login main={true} />
    </>
  );
}



export default LoginPage;