
import React from "react"
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectBox = (props) => {
    const marginTop = props.marginTop !== undefined ? props.marginTop : 3
    const marginBottom = props.marginBottom !== undefined ? props.marginBottom : 3
    return (
        <FormControl fullWidth error={props.error} sx={{mt:marginTop , mb:marginBottom}} size={props.size === undefined ? "normal" : props.size}>
          <InputLabel id="demo-simple-select-error-label">{props.label}</InputLabel>
          <Select labelId="select-box" id="select-box" label={props.label} value={props.defaultValue !== undefined ? props.defaultValue : ''} onChange={props.onChange} key={props.id}>
            {
              props.items?.map(item => {
                return <MenuItem value={item.value} key={"value_"+item.value}>{item.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      )
}

export default SelectBox