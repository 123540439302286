import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    auth: false,
    themeLight: true,
    switchThemeClass:'',
    topLoader:true,
    activityRead: false,
    exceptionRead:false,
    notificationRead: false,
    authToken: null,
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers:{
        setAuthentication: (state, action) => {
            state.auth = action.payload
        },
        setTheme: (state, action) => {
            state.themeLight = action.payload
        },
        setSwitchThemeClass: (state, action) => {
            state.switchThemeClass = action.payload
        },
        setTopLoader: (state, action) => {
            state.topLoader = action.payload
        },
        setActivityRead: (state, action) => {
            state.activityRead = action.payload
        },
        setExceptionRead: (state, action) => {
            state.exceptionRead = action.payload
        },
        setNotificationRead: (state, action) => {
            state.notificationRead = action.payload
        },
        setAuthToken: (state, action) => {
            state.authToken = action.payload
        }
    }
})

// export individual action creator functions
export const { setAuthentication, setTheme, setSwitchThemeClass, setTopLoader, setExceptionRead, setActivityRead, setNotificationRead, setAuthToken } = mainSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default mainSlice.reducer;

