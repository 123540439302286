import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectBoxItems: [],
    clientUploadedImages: [],
    clientUploadProcessLogin: true,
    clientUploadProcessGuestLogin: false,
    drawerOpen: true,
    sideBarRoutes: [],
    actions: [],
    snackbarLoading: false,
    role: null,
    availableBalance: 0.00,
    feedbackDrawerOpen: false
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setSelectBoxItems: (state, action) => {
            state.selectBoxItems = action.payload
        },
        setClientUploadedImages: (state, action) => {
            state.clientUploadedImages = action.payload
        },
        setClientUploadProcessLogin: (state, action) => {
            state.clientUploadProcessLogin = action.payload
        },
        setClientUploadProcessGuestLogin: (state, action) => {
            state.clientUploadProcessGuestLogin = action.payload
        },
        setDrawerOpen: (state, action) => {
            state.drawerOpen = action.payload
        },
        setSideBarRoutes: (state, action) => {
            state.sideBarRoutes = action.payload
        },
        setActions: (state, action) => {
            state.actions = action.payload
        },
        setSnackbarLoading: (state, action) => {
            state.snackbarLoading = action.payload
        },
        setRole: (state, action) => {
            state.role = action.payload
        },
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setUserAvatar: (state, action) => {
            state.userAvatar = action.payload
        },
        setAvalableBalance: (state, action) => {
            state.availableBalance = action.payload
        },
        setFeedbackDrawerOpen: (state, action) => {
            state.feedbackDrawerOpen = action.payload
        },
    }
})


// export individual action creator functions
export const { setSelectBoxItems, setClientUploadedImages, setClientUploadProcessLogin, setClientUploadProcessGuestLogin, setDrawerOpen, setSideBarRoutes, setActions, setSnackbarLoading, setRole, setUserName, setUserAvatar, setAvalableBalance, setFeedbackDrawerOpen } = commonSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default commonSlice.reducer;

