import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import GoogleButton from "react-google-button";
import styles from "./Login.module.css";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import { setClientUploadProcessLogin } from "../../../features/commonSlice/commonSlice";
import { Checkbox, Chip, Divider, FormControlLabel, FormGroup, Link as UiLink } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { authenticate, getGoogleLoginUrl, getNav } from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import { setAuthentication, setAuthToken, setTopLoader } from "../../../features/mainSlice/mainSlice";
import Cookies from "js-cookie";
const Login = (props) => {
  // component states
  const [loginBtnLoad, setLoginBtnLoad] = useState(false);
  const [loadingPostion, setLoadingPostion] = useState("center");
  // Redux states
  const clientUploadProcessLogin = useSelector((state) => state.common.clientUploadProcessLogin);
  // const topLoader = useSelector((state) => state.main.topLoader)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  // form
  const { handleSubmit, control, resetField } = useForm({
    mode: "onChange",
  });

  useEffect(() => {}, []);

  const onSubmit = (data) => {
    // Show loaders
    setLoginBtnLoad(true);
    dispatch(setTopLoader(true));
    // Send auth request
    authenticate(data)
      .then((response) => {
        if (response.data.success === true) {
          if (response.data.data.verification === false) {
            showSuccess(response.data.message);
            navigate("/verify?email=" + response.data.data.email);
            return;
          }
          Cookies.set("isLoggedIn", true, { secure: true });
          Cookies.set("admin", true, { secure: true });
          Cookies.set("token", response.data.data.token, { secure: true });
          dispatch(setAuthToken(response.data.data.token));
          dispatch(setAuthentication(true));
          showSuccess(response.data.message);
          window.location.href = "partners/dashboard";
        }
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
        console.log("error", error);
      });
    resetField("password");
  };
  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  // Load sing up page inside of upload process page
  const handdleUiChangeToSignUp = () => {
    dispatch(setClientUploadProcessLogin(false));
  };
  const signUpWithGoogle = () => {
    dispatch(setTopLoader(true));
    getGoogleLoginUrl()
      .then((response) => {
        console.log(response.data.data);
        if (response.data.success) {
          window.location.href = response.data.data;
        }
        console.log(response);
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      });
  };
  return (
    <Container maxWidth="lg" sx={{ marginTop: 10, marginBottom: 6 }}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh", marginTop: 2, marginBottom: 2 }}>
        <Grid item xs={3}>
          <Card className={styles.login_card}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Typography variant="h4" component={"div"}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography>Login</Typography>
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Welcome back
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={2} display="flex" justifyContent="flex-end">
                          <img src="./favicon.png" className="login-logo" alt="logo" />
                        </Grid> */}
                  </Grid>
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  Please login to start your project in imagemall
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  <Typography variant="caption">Enter your email</Typography>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"email"} id="email" label="email *" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                    rules={{ required: "Email is required", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" } }}
                  />
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  <Grid container>
                    <Grid item xs={6} display="flex" justifyContent="left">
                      <Typography variant="caption">Enter your password</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="right">
                      <UiLink href="/forget-password" underline="none" sx={{ fontSize: 14 }}>
                        {"Forget Password ?"}
                      </UiLink>
                    </Grid>
                  </Grid>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"password"} id="password" label="password *" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                    rules={{
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                      minLength: {
                        value: 6,
                        message: "Password is too short min:6",
                      },
                      maxLength: {
                        value: 14,
                        message: "Password is too long max:14",
                      },
                    }}
                  />
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  <Grid container>
                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel control={<Controller name="remember_me" control={control} defaultValue="" render={({ field: { onChange, value }, fieldState: { error } }) => <Checkbox onChange={onChange} value={value} />} />} label="Remember Me" />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="flex-end">
                      <LoadingButton type="submit" loading={loginBtnLoad} variant={"contained"} sx={{}} size="large">
                        {" "}
                        Login{" "}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: 3 }}>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      Don't have an account yet?
                      {props.main ? (
                        <Link to="/sign-up" className={styles.router_link}>
                          Sign up here
                        </Link>
                      ) : (
                        <UiLink component="button" underline="none" sx={{ fontSize: 14, marginLeft: 1 }} onClick={handdleUiChangeToSignUp}>
                          {" Sign up here."}
                        </UiLink>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 3 }}>
                      <Divider>
                        <Chip label="OR" />
                      </Divider>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" sx={{ marginTop: 3 }}>
                      <GoogleButton onClick={signUpWithGoogle} />
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
              <CardActions></CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
