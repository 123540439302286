import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectComponent = (props) => {
    const {value, error, handleChange } = props
    return (

        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth={true} error={error}>
                <InputLabel id="select-lbl">Service Type*</InputLabel>
                <Select
                    labelId="select-lbl"
                    id="service-select"
                    label="Service Type*"
                    onChange={handleChange}
                    value={value}
                >
                    <MenuItem value="" > <em>Select Service Type</em> </MenuItem>

                    {
                        props.serviceTypes.map((type) => (
                            <MenuItem value={type.value} key={type.value}>{type.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Box>
    )
}
export default SelectComponent;