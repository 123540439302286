import React from "react";
import { Card, CardContent, CardMedia, Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { baseWebURL } from "../../../services/RequestService";
import SelectBox from "../../common/SelectBoxCompnent/SelectBoxComponent";

const MediaCard = (props) => {
  const { jobId, roomsTypeItems, roomsTypeOnChange, selectValue, description, descriptionChange } = props;
  const fileNames = useSelector((state) => state.dropzone.fileNames);
  //Redux states
  return (
    <Grid container spacing={3}>
      {fileNames?.map((item, index) => {
        return (
          <Grid item xs={3} key={index}>
            <Card sx={{ marginTop: 4, borderRadius: 5 }} raised={false}>
              <CardMedia component="img" alt="imagemall file thumbnail" image={baseWebURL + "storage/job-files/" + jobId + "/thumbnails/" + item.filename.split(".")[0] + ".jpg"} sx={{ borderRadius: 5 }} />
              <CardContent>
                <SelectBox
                  id={"select_room_type_"+index}
                  label="Select a vendor"
                  items={roomsTypeItems}
                  onChange={(e)=>roomsTypeOnChange(e, 'selectBox_'+index)}
                  defaultValue={selectValue['selectBox_'+index]}
                  marginBottom={0}
                />
                <TextField id="outlined-multiline-static" label="Describe Your Image" multiline rows={3} defaultValue={description["description_"+index]} sx={{ marginTop: 3 }} onChange={(e)=>descriptionChange(e, 'description_'+index)} fullWidth />
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MediaCard;
